<template lang="pug">
om-color-input(
  label="fill"
  :property="getPath('background.color')"
  typeOfComponent="product"
  :onlySolid="true"
  :editMobile="true"
)
</template>

<script>
  import itemMixin from '../../../mixins/item';

  export default {
    mixins: [itemMixin],
    props: {
      subPath: { type: String, default: '' },
    },
  };
</script>
